<template>
	<div class="app">
		<div class="downloads">
            <div class="main">
                <div class="bg">
                    <img src="../assets/img/download-page-bg.png" alt="" />
                </div>
                <div class="info">
                    <p class="title">
                        <span>007APP</span><span class="yellow">上线了！</span>
                    </p>
                    <p class="desc">看球聊天两不误</p>
                    <div id="qrcode-image" ref="qrcode-image" class="qrcode" />
                    <div class="download-buttons">
                        <a :href="iosUrl" v-if="!!iosUrl">
                            <img class="icon ios" src="../assets/img/download-icon-ios.png" alt="" />
                            iPhone
                        </a>
                        <a download :href="androidUrl" v-if="!!androidUrl">
                            <img class="icon" src="../assets/img/download-icon-android.png" alt="" />
                            Android
                        </a>
                    </div>
                </div>
                <!-- <div class="main-head">

                    <img class="logo" src="../assets/img/v2/download-logo.png" alt="" />
                    <span style="flex: 1"></span>
                    <a href="/" class="home">首页</a>
                </div>
                <div class="main-image">

                    <img class="image" src="../assets/img/v2/download-image.png" alt="" />
                    <div class="text">
                        <img class="text-image" src="../assets/img/v2/download-text.png" alt="" />
                        <div id="qrcode" ref="qrcode" class="qrcode" />
                        <span>支持iPhone&Android</span>
                    </div>
                </div> -->
            </div>
            <!-- <div class="download">
                <div class="download-head">下载APP</div>
                <div class="download-meta">请选择下载方式</div>
                <div class="download-buttons">
                    <a :href="ios" v-if="!!ios">
                        <img class="icon" src="../assets/img/v2/download-icon-ios.png" alt="" />
                        iPhone
                    </a>
                    <a download :href="apk" v-if="!!apk">
                        <img class="icon" src="../assets/img/v2/download-icon-android.png" alt="" />
                        Android
                    </a>
                </div>
            </div> -->
        </div>
		<!-- <div class="app-swiper" :style="style">
			<swiper :options="swiperOption">
				<swiper-slide class="swiper-slide">
					<div class="swiper-item">
						<div class="left">
							<img src="../assets/img/app0101.png" class="app0101" />
							<div class="info">
								<div class="qrcode">
                                    <div id="qrcode-image" ref="qrcode-image" class="qrcode-image" />
									<div>{{$t('goToScan')}}</div>
								</div>
								<div class="downloader">
									<a :href='iosUrl'><img src="../assets/img/app0104.png" /></a>
									<a :href='androidUrl'><img src="../assets/img/app0105.png" /></a>
								</div>
							</div>
						</div>
						<div class="right"><img src="../assets/img/app0110.png" class="app0101" /></div>
					</div>
				</swiper-slide>
				<swiper-slide class="swiper-slide">
					<div class="swiper-item">
						<div class="left left-center">
							<img src="../assets/img/app0201.png" class="app0201" />
							<div class="text">
                  {{$t('adContent1')}}
								<br />
                  {{$t('adContent2')}}
							</div>
						</div>
						<div class="right"><img src="../assets/img/app0120.png" class="app0101" /></div>
					</div>
				</swiper-slide>
				<swiper-slide class="swiper-slide">
					<div class="swiper-item">
						<div class="left left-center">
							<img src="../assets/img/app0301.png" class="app0301" />
							<div class="text">
                  {{$t('adContent3')}}
								<br />
                  {{$t('adContent4')}}
							</div>
						</div>
						<div class="right"><img src="../assets/img/app0130.png" class="app0101" /></div>
					</div>
				</swiper-slide>
				<swiper-slide class="swiper-slide">
					<div class="swiper-item">
						<div class="left left-center">
							<img src="../assets/img/app0401.png" class="app0401" />
							<div class="text">
                  {{$t('adContent5')}}
								<br />
                  {{$t('adContent6')}}
							</div>
						</div>
						<div class="right"><img src="../assets/img/app0140.png" class="app0101" /></div>
					</div>
				</swiper-slide>
				<swiper-slide class="swiper-slide">
					<div class="swiper-item">
						<div class="left">
							<img src="../assets/img/app0101.png" class="app0101" />
							<div class="info">
								<div class="qrcode">
                                    <div id="qrcode-image2" ref="qrcode-image" class="qrcode-image" />
									<div>{{$t('goToScan')}}</div>
								</div>
					
								<div class="downloader">
									<a :href='iosUrl'><img src="../assets/img/app0104.png" /></a>
									<a :href='androidUrl'><img src="../assets/img/app0105.png" /></a>
								</div>
							</div>
						</div>
						<div class="right"><img src="../assets/img/app0150.png" class="app0101" /></div>
					</div>
				</swiper-slide>
				<div class="swiper-button-prev" slot="button-prev"></div>
				<div class="swiper-button-next" slot="button-next"></div>
			</swiper>

			<div class="swiper-pagination" :style="{ width: style.width }"></div>
		</div> -->
	</div>
</template>

<script>
import { debounce } from '@/utils/index.js';
import { DEV_MODE } from '@/utils/constants.js'
import { mapState } from 'vuex';
import QRCode from "qrcodejs2";
export default {
	data() {
		return {
			style: {
				width: 'auto',
				height: 'auto'
			},
			swiperOption: {
				pagination: '.swiper-pagination',
				paginationClickable: true,
				prevButton: '.swiper-button-prev',
				nextButton: '.swiper-button-next'
			},
			isDev: location.hostname == 'dev-web.01tiyu.com'
		};
	},
	computed: mapState(['iosUrl', 'androidUrl']),
	mounted() {
		window.addEventListener('resize', debounce(this.calcRect, 300));
		this.calcRect();
		this.$store.dispatch('getDownloadUrls');
        setTimeout(this.makeQrcode, 0)
	},
	methods: {
		calcRect(e) {
			const style = window.getComputedStyle(this.$root.$el);
			let width = parseFloat(style.width);
			let height = Math.max(parseFloat(style.height), 500) - 100;
			if (width / 2 > height - 100) {
				width = (height - 100) * 2;
			} else {
				height = width / 2 + 100;
			}
			this.style.width = width + 'px';
			this.style.height = height + 'px';
		},
        makeQrcode() {
            const hostname = (location.hostname).split('.')
			const vhost = hostname.slice(hostname.length - 2)
			vhost.unshift(DEV_MODE ? 'dev-m' : 'm')
            const text = location.protocol + '//' + vhost.join('.') + '/pages/common/download'
            new QRCode("qrcode-image", {
                width: 230, //宽度
                height: 230, // 高度
                text: text
            });
            new QRCode("qrcode-image2", {
                width: 230, //宽度
                height: 230, // 高度
                text: text
            });
        }
	}
};
</script>

<style lang="less">

// body.page-app {
// 	.app {
// 		height: 100%;
// 		// background: url(../assets/img/downloadbg.png) no-repeat center center;
// 		// background-size: cover;
// 	}
// 	.footer {
// 		display: none;
// 	}
// 	.app-swiper {
// 		padding: 50px 0;
// 		margin-left: auto;
// 		margin-right: auto;
// 		box-sizing: border-box;
// 		.swiper-container {
// 			width: 100%;
// 			height: 100%;
// 			background: url(../assets/img/swiperbg.png) no-repeat center center;
// 			background-size: contain;
// 		}
// 		.swiper-item {
// 			width: 100%;
// 			height: 100%;
// 			position: relative;
// 			padding-left: 16%;
// 			padding-right: 12.5%;
// 			padding-top: 0;
// 			display: flex;
// 			flex-direction: row;
// 			align-items: center;
// 			box-sizing: border-box;
// 			// height: auto;
// 			.left {
// 				flex: 1;
// 				display: flex;
// 				flex-direction: column;
// 				&.left-center {
// 					position: relative;
// 					align-items: center;
// 				}
// 			}
// 			.right {
// 				width: 30%;
// 				margin-left: 16%;
// 			}

// 			.text {
// 				width: 100%;
// 				margin-top: 20%;
// 				font-size: 1.2vw;
// 				color: #ffffff;
// 				text-align: center;
// 			}
// 			.app0201,
// 			.app0301,
// 			.app0401 {
// 				width: 61.25%;
// 			}
// 		}
// 		.swiper-slide {
// 			display: flex;
// 			flex-direction: row;
// 			align-items: center;
// 			justify-content: center;
// 		}
// 		.swiper-button-prev {
// 			left: 100px;
// 			background-size: 18px 34px;
// 		}

// 		.swiper-button-next {
// 			right: 100px;
// 			background-size: 18px 34px;
// 		}

// 		.swiper-button-disabled {
// 			display: none;
// 		}

// 		.info {
// 			position: relative;
// 			margin-top: 20%;
// 			display: flex;
// 			flex-direction: row;
// 			font-size: 1.2vw;

// 			.qrcode {
// 				flex: 1;
// 				color: #ffffff;
// 				text-align: center;
//                 .qrcode-image {
//                     width: 100%;
//                     background-color: #ffffff;
//                     padding: 4%;
//                     margin-bottom: 8%;
//                 }

//                 img {
//                     width: 100%!important;
//                     height: auto!important;
//                     display: block;
//                 }
// 			}

// 			.downloader {
// 				margin-left: 16%;
// 				width: 50%;
// 				a,
// 				img {
// 					display: block;
// 				}
// 				a {
// 					margin-bottom: 18%;
// 				}
// 			}
// 		}
// 		img {
// 			max-width: 100%;
// 		}
// 	}

// 	.swiper-pagination {
// 		height: 50px;
// 		display: flex;
// 		flex-direction: row;
// 		align-items: center;
// 		justify-content: center;
// 		.swiper-pagination-bullet {
// 			opacity: 1;
// 			width: 16px;
// 			height: 16px;
// 			background: #ffffff;
// 			border-radius: 50%;
// 			+ .swiper-pagination-bullet {
// 				margin-left: 28px;
// 			}
// 			&.swiper-pagination-bullet-active {
// 				background: var(--primary-color);
// 			}
// 		}
// 	}
// }
.downloads {
    padding: 0;
    background-color: #FFFFFF;

    .main {
        margin: 0 auto;
        box-sizing: border-box;
        display: flex;
        gap: 30px;
        padding-top: 50px;
        .bg {
            width: 800px;
			margin-left: 200px;
            img {
                width: 800px;
            }
        }
        .info {
            .title {
                padding-top: 30px;
                font-size: 48px;
                color: #333;
                font-weight: bold;
                .yellow {
                    color: #FFC927;
                }
            }
            .desc {
                font-size: 38px;
                color: #333333;
				letter-spacing: 3px;
				font-weight: 100;
            }
        }

        .qrcode {
            margin-top: 60px;
            width: 290px;
            height: 290px;
            background: #FFFFFF;
            box-shadow: 0px 10px 40px 0px rgba(255,255,255,0.6);
            border-radius: 40px 40px 40px 40px;
            border: 1px solid #FFC927;
            padding: 30px;
        }

        &-head {
            display: flex;
            align-items: center;

            .logo {
                width: 14.8vw;
            }

            .home {
                padding: 0.8vw 1.6vw;
                font-size: 1.7vw;
                line-height: 1;
                font-weight: bold;
                color: #fffefe;
                text-decoration: none;
                border: #fff 0.1vw solid;
                background-color: rgba(255, 255, 255, 0.2);
            }
        }

        &-image {
            margin-top: 3.6vw;
            display: flex;

            .image {
                display: block;
                width: 37vw;
            }

            .text {
                margin-left: 9vw;
                width: 22vw;
                padding-top: 7vw;
                display: flex;
                flex-direction: column;
                align-items: center;

                font-size: 1.5vw;
                font-weight: bold;
                color: #ffffff;

                &-image {
                    display: block;
                    width: 22vw;
                }

                .qrcode {
                    margin-top: 4vw;
                    display: block;
                    width: 13.6vw;
                    height: 13.6vw;
                    margin-bottom: 1vw;
                    background-color: #fff;
                    padding: .3vw;

                    img {
                        display: block;
                        width: 13vw !important;
                        height: 13vw !important;
                    }
                }
            }
        }
    }

    .download {
        padding: 9.7vw 0 13vw;
        display: flex;
        flex-direction: column;
        align-items: center;

        &-head {
            position: relative;
            font-size: 2.6vw;
            font-weight: bold;
            color: #373737;
            padding-bottom: 2vw;

            &::after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                width: 7.3vw;
                height: 0.6vw;
                background: #74b3ff;
                border-radius: 0.3vw;
            }
        }

        &-meta {
            margin-top: 3.8vw;
            font-size: 1.8vw;
            font-weight: 500;
            color: #98999a;
        }

        &-buttons {
            margin-top: 40px;

            a {
                display: block;
                width: 16.7vw;
                height: 4.1vw;
                border-radius: 2vw;
                display: flex;
                align-items: center;
                text-decoration: none;
                font-size: 2vw;
                color: #333333;
                background: #FFC927;
                margin-bottom: 20px;
            }

            .icon {
                width:  31px;
                margin: 0 40px;
            }

            .ios {
                width: 31px;
            }
        }
    }
}
</style>
